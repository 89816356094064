
import { AnnouncementApi, PublishAnnouncementOptions } from '@/api/AnnouncementApi';
import AnnouncementImageDropzone from '@/components/announcement/AnnouncementImageDropzone.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { Bot, Chain, PublishAnnouncement } from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'publish-announcement-card',
  components: { AnnouncementImageDropzone },
})
export default class PublishAnnouncementCard extends Vue {
  @Prop({ required: false, type: Object })
  readonly chain!: Chain;

  @Prop({ required: false, type: Object })
  readonly bot!: Bot;

  @Prop({ required: true, type: String })
  readonly type!: 'chain' | 'bot';

  announcementData = this.resetValue();
  announcementImageHorizontal: File | null = null;
  announcementImageSquare: File | null = null;

  selectedBots: Bot[] = [];

  isSubmitting = false;

  get canSubmit(): boolean {
    return !!this.announcementData.title && !!this.announcementData.content;
  }

  get id(): string {
    return this.type === 'chain' ? this.chain.id : this.bot.id;
  }

  mounted() {
    if (this.chain) {
      this.selectedBots = this.chain.bots?.slice() || [];
    }
  }

  async onSubmit() {
    if (!this.canSubmit) {
      return;
    }
    this.isSubmitting = true;
    try {
      const publishOptions: PublishAnnouncementOptions = {
        id: this.id,
        data: this.announcementData,
        images: {
          horizontal: this.announcementImageHorizontal,
          square: this.announcementImageSquare,
        } ,
      };
      const result = await (this.type === 'bot'
        ? AnnouncementApi.publishBotAnnouncement(publishOptions)
        : AnnouncementApi.publishChainAnnouncement({
            ...publishOptions,
            botIds: this.selectedBots.map((bot) => bot.id),
          }));

      this.$emit('publish', result);
      this.$notify.success('Successfully published announcement');
      this.reset();
    } catch (e) {
      this.$notify.error({
        title: 'Publishing announcement failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  onRemoveBot(bot: Bot) {
    this.selectedBots = this.selectedBots.filter((b: Bot) => b.id !== bot.id);
  }

  onResetBots() {
    this.selectedBots = this.chain.bots?.slice() || [];
  }

  get hasRemoved() {
    return this.selectedBots.length !== this.chain.bots?.length;
  }

  onImageInput(files: { square: File, horizontal: File }) {
    this.announcementImageHorizontal = files.horizontal;
    this.announcementImageSquare = files.square;
  }

  @Watch('bot', { deep: true })
  @Watch('chain', { deep: true })
  reset() {
    this.announcementData = this.resetValue();
    this.announcementImageHorizontal = null;
    this.announcementImageSquare = null;
  }

  resetValue(): PublishAnnouncement {
    return {
      title: '',
      content: '',
      callToAction: {
        title: '',
        url: '',
      },
    };
  }
}
