
import AnnouncementsListCardItem from '@/components/announcement/AnnouncementsListCardItem.vue';
import { Announcement, Bot, Chain } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'announcements-list-card',
  components: { AnnouncementsListCardItem },
})
export default class AnnouncementsListCard extends Vue {
  @Prop({ required: true, type: Array })
  announcements!: Announcement[];

  @Prop({ required: false, type: Object })
  readonly chain!: Chain;

  @Prop({ required: false, type: Object })
  readonly bot!: Bot;

  sortAnnouncements = (a: Announcement, b: Announcement) => {
    const lastUpdatedA = new Date(a.lastUpdatedAt!).getTime();
    const lastUpdatedB = new Date(b.lastUpdatedAt!).getTime();
    if (lastUpdatedA > lastUpdatedB) {
      return -1;
    } else if (lastUpdatedA < lastUpdatedB) {
      return 1;
    }
    return 0;
  };
  filterAnnouncements = (announcement: Announcement, filterBy: string) =>
    announcement.title.toLowerCase().includes(filterBy) ||
    announcement.content.toLowerCase().includes(filterBy);
  getAnnouncementKey = (announcement: Announcement) => announcement.id;
}
