
import { SOCIAL_PLATFORMS_METADATA } from '@/constants';
import { PlatformMetadata } from '@/types';
import { Announcement, AnnouncementPublishInfo } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'announcements-list-card-item',
})
export default class AnnouncementsListCardItem extends Vue {
  @Prop({ required: true, type: Object })
  announcement!: Announcement;

  get relatedPlatformMetadata(): PlatformMetadata[] {
    if (!this.announcement.publishInfo) return [];
    const relatedMetadataList: PlatformMetadata[] = [];

    for (const key in this.announcement.publishInfo) {
      if (this.announcement.publishInfo[key as keyof AnnouncementPublishInfo]) {
        const relatedMetadata = SOCIAL_PLATFORMS_METADATA.find(
          (metadata) => metadata.value === key,
        );
        if (relatedMetadata) {
          relatedMetadataList.push(relatedMetadata);
        }
      }
    }

    return relatedMetadataList;
  }
}
