import { Api, BOTS_BASE_URL, CHAINS_BASE_URL } from '@/api/index';
import { getCompressedImage } from '@/utilities';
import axios from 'axios';
import { Announcement, PublishAnnouncement } from 'ignite360-core';

export interface PublishAnnouncementOptions {
  [key: string]: unknown;
  id: string;
  data: PublishAnnouncement;
  images?: { horizontal: File | null; square: File | null; };
}

export interface PublishChainAnnouncementOptions extends PublishAnnouncementOptions {
  botIds: string[];
}

export class AnnouncementApi {
  static async loadBotAnnouncements(botId: string): Promise<Announcement[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${BOTS_BASE_URL}/${botId}/announcements`,
    });
    const result = await axios.request<Announcement[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    return [];
  }

  static async loadChainAnnouncements(chainId: string): Promise<Announcement[]> {
    const config = Api.finalizeConfig({
      method: 'GET',
      url: `${CHAINS_BASE_URL}/${chainId}/announcements`,
    });
    const result = await axios.request<Announcement[]>(config);
    if (result.status === 200 && result.data) {
      return result.data;
    }
    return [];
  }

  static async publishChainAnnouncement(
    options: PublishChainAnnouncementOptions,
  ): Promise<Announcement[]> {
    return (await AnnouncementApi.publishAnnouncement(CHAINS_BASE_URL, options)) as Announcement[];
  }

  static async publishBotAnnouncement(options: PublishAnnouncementOptions): Promise<Announcement> {
    return (await AnnouncementApi.publishAnnouncement(BOTS_BASE_URL, options)) as Announcement;
  }

  private static async publishAnnouncement(
    baseUrl: string,
    { id, data, images, botIds }: PublishAnnouncementOptions | PublishChainAnnouncementOptions,
  ): Promise<Announcement | Announcement[]> {
    const formData = new FormData();

    if (data.title) {
      formData.append('title', data.title);
    }
    if (data.content) {
      formData.append('content', data.content);
    }
    if (data.callToAction?.title) {
      formData.append('callToAction[title]', data.callToAction.title);
    }
    if (data.callToAction?.url) {
      formData.append('callToAction[url]', data.callToAction.url);
    }
    if (images?.square && images.horizontal) {
      const compressedImage = await getCompressedImage(images.horizontal);
      formData.append('imageHorizontal', compressedImage);

      const compressedImageSquare = await getCompressedImage(images.square);
      formData.append('imageSquare', compressedImageSquare);
    }

    if (Array.isArray(botIds)) {
      botIds.forEach((botId: string) => {
        formData.append('botIds[]', botId);
      });
    }

    const config = Api.finalizeConfig({
      method: 'POST',
      url: `${baseUrl}/${id}/announcements`,
      data: formData,
    });
    const result = await axios.request<Announcement>(config);
    if (result.status === 201 && result.data) {
      return result.data;
    }
    throw new Error('Could not publish announcements');
  }
}
