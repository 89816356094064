
import { AnnouncementApi } from '@/api/AnnouncementApi';
import AnnouncementsListCard from '@/components/announcement/AnnouncementsListCard.vue';
import PublishAnnouncementCard from '@/components/announcement/PublishAnnouncementCard.vue';
import { ChainViewMixin } from '@/mixins/ChainViewMixin';
import { Announcement, Chain } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

@Component({
  name: 'chain-content-announcements',
  components: { AnnouncementsListCard, PublishAnnouncementCard },
})
export default class ChainContentAnnouncements extends mixins(ChainViewMixin) {
  loadedAnnouncements: Announcement[] = [];

  @Watch('chain', { immediate: true })
  async onChainChange(chain: Chain) {
    await this.loadAnnouncements(chain);
  }

  async loadAnnouncements(chain: Chain) {
    this.loadedAnnouncements = await AnnouncementApi.loadChainAnnouncements(chain.id);
  }
}
