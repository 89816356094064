import { Component, Vue } from 'vue-property-decorator';
import { Chain } from 'ignite360-core';
import { AppRoute } from '@/router/routes';

@Component
export class ChainViewMixin extends Vue {
  get chain(): Chain | undefined {
    return this.$store.state.chains.chains.find(
      (chain: Chain) => chain.id === this.$route.params.id,
    );
  }
  mounted() {
    if (!this.chain) {
      // TODO test in production with more delay
      return this.$router.replace({ name: AppRoute.Chains });
    }
  }
}
